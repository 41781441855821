import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  useTheme,
  Stack,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  Box,
  Button,
  Skeleton,
} from "@mui/material";
import { useMovieMetadata } from "../hooks/useCustomQuery";
import CloseIcon from "@mui/icons-material/Close";
import TheaterComedyIcon from "@mui/icons-material/TheaterComedy";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import LanguageIcon from "@mui/icons-material/Language";
import TheatersIcon from "@mui/icons-material/Theaters";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CircularProgress } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddTaskIcon from "@mui/icons-material/AddTask";
import {
  getYear,
  getStatusOptions,
  getStatusOptionsArray,
} from "../utils/utils";
import ListMenu from "./ListMenu";
import ConfirmDeletePopover from "./ConfirmDelete";
import useResizeObserver from "../hooks/useResizeObserver";

const languageNames = new Intl.DisplayNames(["en"], { type: "language" });

function MovieCard({
  tmdbId,
  onClose,
  status,
  onStatusChange,
  disableStatusChange,
  fullscreen,
}) {
  const theme = useTheme();
  const { data: metaData, isLoading } = useMovieMetadata(tmdbId);
  const statusOptions = getStatusOptions(theme);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [deleteAnchorEl, setDeleteAnchorEl] = useState(null);
  const [isDirectorOverflowing, setIsDirectorOverflowing] = useState(false);
  const [isOverviewOverflowing, setIsOverviewOverflowing] = useState(false);
  const [isOverviewExpanded, setIsOverviewExpanded] = useState(false);
  const statusButtonRef = useRef(null);
  const directorRef = useRef(null);
  const overviewRef = useRef(null);
  const { width: directorWidth } = useResizeObserver(directorRef);
  const { width: overviewWidth } = useResizeObserver(overviewRef);

  useEffect(() => {
    if (directorRef.current) {
      setIsDirectorOverflowing(
        directorRef.current.scrollWidth > directorRef.current.offsetWidth
      );
    }
  }, [directorWidth]);

  useEffect(() => {
    if (overviewRef.current) {
      setIsOverviewOverflowing(
        overviewRef.current.scrollHeight > overviewRef.current.clientHeight
      );
    }
  }, [overviewWidth]);

  const baseStatusOptionsArray = getStatusOptionsArray(theme).filter(
    (option) => option.value !== "none" && option.value !== status
    // remove default "none" option as well as the current status
  );
  const statusOptionsDropdown = [
    ...(status === "finished"
      ? [
          {
            label: "Log again",
            value: "logAgain",
            icon: <AddTaskIcon />,
            color: theme.palette.status.finished.main,
          },
        ]
      : []),
    ...baseStatusOptionsArray.map((option) => ({
      ...option,
      label: option.action,
    })),
    ...(status !== "none"
      ? [
          {
            label: "Delete",
            value: "none",
            icon: <DeleteOutlineIcon />,
            color: "#bdbdbd",
          },
        ]
      : []),
  ].filter(Boolean); // needed to filter out falsey values that originate due to the conditions used in constructing the array

  const currentStatus =
    status !== "loading" ? statusOptions[status] : undefined;
  const hours = Math.floor(metaData?.runtime / 60);
  const minutes = metaData?.runtime % 60;
  const originalLanguage = metaData?.original_language
    ? languageNames.of(metaData?.original_language)
    : "?";

  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleStatusSelect = (option) => (event) => {
    // This function needs to accept an event even though it is not used as that
    // is how the onClick prop of the ListMenu component is configured
    setStatusAnchorEl(null);
    if (option === "none") {
      setDeleteAnchorEl(statusButtonRef.current);
    } else {
      onStatusChange(option);
    }
  };

  const handleConfirmDelete = () => {
    onStatusChange("none");
    setDeleteAnchorEl(null);
  };

  const loadingDiv = (
    <Stack direction="column" spacing={1}>
      <Skeleton width="70%" />
      <Skeleton width="60%" />
      <Skeleton width="50%" />
    </Stack>
  );

  const titleDirectorYearDiv = (
    <Stack
      direction="row"
      justifyContent="space-between"
      spacing={1}
      alignItems="flex-start"
      sx={{ pr: 2 }}
    >
      <Box
        sx={{
          "& > span": {
            marginRight: 2,
          },
        }}
      >
        <Typography
          variant="h6"
          component="span"
          sx={{
            fontWeight: "bold",
            display: "inline-block",
          }}
        >
          {metaData?.title}
        </Typography>
        <Box
          component="span"
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          <Box sx={{ display: "inline-flex" }}>
            <Tooltip
              // Conditionally display tooltip with full name of director in the event it becomes clipped due to overflow
              title={metaData?.director}
              disableHoverListener={!isDirectorOverflowing}
              placement="bottom-start"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -14],
                      },
                    },
                  ],
                },
              }}
            >
              <Typography
                ref={directorRef}
                variant="subtitle1"
                component="span"
                sx={{
                  marginRight: 2,
                  maxWidth: {
                    xs: "160px",
                    sm: "260px",
                    md: "360px",
                  },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {metaData?.director}
              </Typography>
            </Tooltip>
          </Box>
          <Typography
            variant="subtitle1"
            component="span"
            color="text.secondary"
            sx={{ display: "inline-block" }}
          >
            <i>{getYear(metaData?.release_date)}</i>
          </Typography>
        </Box>
      </Box>
    </Stack>
  );

  const movieStatsDiv = (
    <Box
      sx={{
        flexWrap: "wrap",
        display: "flex",
        direction: "row",
        alignItems: "center",
        gap: 1,
      }}
    >
      <Stack direction="row" spacing={1} sx={{ mr: 1 }}>
        <AccessTimeFilledIcon fontSize="small" />
        <Typography variant="body2" color="text.secondary">
          {hours}h {minutes}m
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1} sx={{ mr: 1 }}>
        <TheatersIcon fontSize="small" />
        <Typography variant="body2" color="text.secondary">
          {metaData?.certification}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={1}>
        <LanguageIcon fontSize="small" />
        <Typography variant="body2" color="text.secondary">
          {originalLanguage}
        </Typography>
      </Stack>
    </Box>
  );

  const genresDiv = (
    <Stack direction="row" spacing={1} alignItems="flex-start">
      <Box sx={{ pt: "3px" }}>
        <TheaterComedyIcon fontSize="small" />
      </Box>
      <Box
        sx={{
          flexWrap: "wrap",
        }}
      >
        {metaData?.genres.map((genre) => (
          <Chip
            key={genre.id}
            label={genre.name.toLowerCase()}
            size="small"
            variant="filled"
            sx={{
              "&.MuiChip-root": {
                mt: 0.5,
                mb: 0.5,
                mr: 1,
              },
            }}
          />
        ))}
      </Box>
    </Stack>
  );

  return (
    <>
      <Card
        sx={{
          display: "flex",
          position: "relative",
          width: "100%",
          backgroundImage: fullscreen ? "none" : "auto",
          boxShadow: fullscreen ? "none" : "auto",
        }}
      >
        <CardContent
          sx={{
            padding: 2,
            width: "100%",
            "&:last-child": {
              paddingBottom: 2,
            },
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <CardMedia
                component={isLoading ? Skeleton : "img"}
                variant={isLoading ? "rectangular" : undefined}
                sx={{
                  width: "100%",
                  height: isLoading ? 0 : "auto",
                  pt: isLoading && "150%",
                  borderRadius: 1,
                  flexShrink: 0,
                }}
                image={
                  isLoading
                    ? undefined
                    : `https://image.tmdb.org/t/p/w185/${metaData?.poster_path}`
                }
                alt={
                  isLoading
                    ? "Movie poster loading"
                    : `${metaData?.title} poster`
                }
              />
            </Grid>
            <Grid
              item
              xs={9}
              position="relative"
              display="flex"
              flexDirection="column"
            >
              {isLoading ? (
                loadingDiv
              ) : (
                <>
                  <Box flexGrow={1}>
                    <Stack direction="column" spacing={1}>
                      {titleDirectorYearDiv}
                      {movieStatsDiv}
                      {genresDiv}
                      <Typography
                        ref={overviewRef}
                        variant="body2"
                        sx={{
                          display: isOverviewExpanded ? "block" : "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: isOverviewExpanded ? "unset" : 3,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          cursor:
                            isOverviewOverflowing || isOverviewExpanded
                              ? "pointer"
                              : "default",
                        }}
                        onClick={() => {
                          if (isOverviewOverflowing || isOverviewExpanded) {
                            setIsOverviewExpanded(!isOverviewExpanded);
                          }
                        }}
                      >
                        {metaData.overview}
                      </Typography>
                    </Stack>
                    <Tooltip title="Close">
                      <IconButton
                        onClick={onClose}
                        sx={{
                          position: "absolute",
                          top: 16,
                          right: 0,
                          p: 0.5,
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Tooltip
                      title={
                        disableStatusChange || status === "loading"
                          ? ""
                          : "Update status"
                      }
                    >
                      <Button
                        ref={statusButtonRef}
                        variant="contained"
                        startIcon={currentStatus?.iconWhite}
                        endIcon={
                          disableStatusChange ? undefined : <ExpandMoreIcon />
                        }
                        // The following three props are syntaxed as below to ensure tooltip is operational when wrapped around a disabled button
                        onClick={
                          disableStatusChange || status === "loading"
                            ? undefined
                            : handleStatusClick
                        }
                        disabled={disableStatusChange || status === "loading"}
                        component={
                          disableStatusChange || status === "loading"
                            ? "div"
                            : undefined
                        }
                        // color={`${statusOptions[status.color]}`} Ideally I want to set the color here so that color is applied to button properly (i.e. on hover also)
                        sx={{
                          boxShadow: "none",
                          height: "38px",
                          backgroundColor: currentStatus?.color, //
                          color: currentStatus?.contrastText,
                          "&:hover": {
                            backgroundColor: currentStatus?.color, // Ensure hover state retains the same color
                          },
                          "&.Mui-disabled": {
                            pointerEvents: "auto",
                            backgroundColor: currentStatus?.color,
                            color: "white",
                            opacity: 1, // Ensures the color remains unchanged
                          },
                        }}
                      >
                        {status === "loading" ? (
                          <CircularProgress size="1rem" color="inherit" />
                        ) : (
                          currentStatus?.label
                        )}
                      </Button>
                    </Tooltip>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <ListMenu
        anchorEl={statusAnchorEl}
        onClose={() => setStatusAnchorEl(null)}
        onSelect={handleStatusSelect}
        options={statusOptionsDropdown}
      />

      <ConfirmDeletePopover
        anchorEl={deleteAnchorEl}
        onCancel={() => setDeleteAnchorEl(null)}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
}

export default MovieCard;
