import React from "react";
import { useThemeContext } from "../../context/themeContext";
import { Button, Card, Stack, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as ExploreIcon } from "../../assets/IconExplore.svg";

export default function EmptyActivity({ onOpenUserSearch }) {
  const { mode } = useThemeContext();
  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        mb: 2,
        maxWidth: "sm",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        bgcolor: (theme) =>
          mode === "dark"
            ? theme.palette.background.paper
            : theme.palette.secondary.main,
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent={{ xs: "center", md: "flex-start" }}
        spacing={2}
        mb={2}
      >
        <SvgIcon
          sx={{
            width: { xs: 75, sm: 100 },
            height: { xs: 75, sm: 100 },
            color: "primary.main",
          }}
        >
          <ExploreIcon />
        </SvgIcon>
        <Typography>
          Welcome to the Explore page! Here, you’ll see what the people you
          follow are watching, reading, and curating — helping you discover your
          next great recommendation. Follow others to start filling your feed!
        </Typography>
      </Stack>
      <Button
        variant="contained"
        sx={{ alignSelf: "flex-end" }}
        onClick={onOpenUserSearch}
      >
        Find people to follow
      </Button>
    </Card>
  );
}
