import { Outlet } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { AuthProvider } from "../context/authContext";

export default function RootLayout() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <Outlet />
    </AuthProvider>
  );
}
