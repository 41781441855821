import { useQuery } from "@tanstack/react-query";
import useApi from "./useApi";

export function useMovieMetadata(tmdbId) {
  const { fetchMovieMetaData } = useApi();
  // const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["movie", "metaData", tmdbId],
    queryFn: () => fetchMovieMetaData(tmdbId),
    // queryFn: () => {
    //   const cachedData = queryClient.getQueryData([
    //     "movie",
    //     "metaData",
    //     tmdbId,
    //   ]);
    //   if (cachedData) {
    //     console.log("cached data exists");
    //     return cachedData;
    //   } else {
    //     console.log("cached data does not exist");
    //     return fetchMovieMetaData(tmdbId);
    //   }
    // },
    enabled: !!tmdbId,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

export function useBookMetadata(olWid) {
  const { fetchBookMetaData } = useApi();

  return useQuery({
    queryKey: ["book", "metaData", olWid],
    queryFn: () => fetchBookMetaData(olWid),
    enabled: !!olWid,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}
