import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Link,
  Rating,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useRef, useState } from "react";
import {
  getYear,
  reactionOptions,
  getStatusOptions,
  getStatusOptionsArray,
} from "../../utils/utils";
import { useMovieMetadata } from "../../hooks/useCustomQuery";
import { useTheme } from "@emotion/react";
import { useThemeContext } from "../../context/themeContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useApi from "../../hooks/useApi";
import { toast } from "react-toastify";
import { Link as RouterLink } from "react-router-dom";
import {
  deleteMovieStatusInCache,
  updateMovieStatusInCache,
} from "../../utils/cacheUtils";

export default function Activity({ item, onPosterClick }) {
  const {
    movie_tmdb_id,
    movie_title,
    movie_release_date,
    notes,
    rating,
    reactions,
    user_given_name,
    username,
    user_picture_url,
    entry_status,
    user_status,
  } = item;
  const theme = useTheme();
  const queryClient = useQueryClient();
  const isBelowSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { isTouchDevice } = useThemeContext();
  const { data: metaData, isLoading } = useMovieMetadata(movie_tmdb_id);
  const statusOptions = getStatusOptions(theme);
  const posterActions = getStatusOptionsArray(theme).filter(
    (option) => option.value !== "none"
  );
  const [showPosterOverlay, setShowPosterOverlay] = useState(false);
  const timerRef = useRef(null); // timer for long press
  const { addStatus, deleteStatus } = useApi();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const addStatusMutation = useMutation({
    mutationFn: ({ tmdb_id, status, createJournalEntry }) =>
      addStatus(tmdb_id, status, createJournalEntry),
    onSuccess: (data, variables) => {
      setShowPosterOverlay(false);
      updateMovieStatusInCache(
        queryClient,
        movie_tmdb_id,
        user_status,
        data.statusObject,
        data.entryObject
      );
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
      // }
    },
  });

  const deleteStatusMutation = useMutation({
    mutationFn: ({ tmdb_id, confirm }) => deleteStatus(tmdb_id, confirm),
    onSuccess: (data, variables) => {
      console.log(variables.tmdb_id);
      if (data.require_confirmation) {
        setOpenConfirmDelete(true);
      } else {
        setShowPosterOverlay(false);
        deleteMovieStatusInCache(queryClient, variables.tmdb_id);
        toast.success(data.message);
        setOpenConfirmDelete(false);
      }
    },
    onError: (error) => {
      toast.error(error.response?.data?.message || "Failed to remove status.");
    },
  });

  const handleTouchStart = (event) => {
    timerRef.current = setTimeout(() => {
      setShowPosterOverlay(false);
      onPosterClick?.();
    }, 300); // long-press threshold in ms
  };

  const handleTouchEnd = () => {
    clearTimeout(timerRef.current);
  };

  const handleTouchMove = () => {
    clearTimeout(timerRef.current);
    setShowPosterOverlay(false);
  };

  const onTap = () => {
    setShowPosterOverlay(!showPosterOverlay);
  };

  const handlePosterActionClick = (status) => {
    if (status === user_status) {
      deleteStatusMutation.mutate({
        tmdb_id: movie_tmdb_id,
        confirm: false,
      });
    } else {
      addStatusMutation.mutate({
        tmdb_id: movie_tmdb_id,
        status,
        createJournalEntry: true,
      });
    }
  };

  const mainDiv =
    !reactions && !notes ? null : (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "",
          mt: isBelowSm && 1,
        }}
      >
        {reactions && (
          <Box
            sx={{
              flexWrap: "wrap",
              mb: 1,
            }}
          >
            {reactions.map((reaction, index) => {
              const option = reactionOptions.find(
                (option) => option.label === reaction
              );
              return (
                <Chip
                  key={index}
                  label={reaction}
                  icon={
                    option?.icon || (
                      <span role="img" aria-label={option?.label}>
                        {option?.emoji}
                      </span>
                    )
                  }
                  size="small"
                  sx={{
                    "&.MuiChip-root": {
                      mb: 0.5,
                      mt: 0.5,
                      mr: 1,
                    },
                  }}
                />
              );
            })}
          </Box>
        )}

        {notes && <Typography> {notes} </Typography>}
      </Box>
    );

  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        maxWidth: "sm",
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        boxShadow: "none",
        // bgcolor:
        //   mode === "dark"
        //     ? theme.palette.background.paper
        //     : theme.palette.secondary.main,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
        }}
      >
        <Box
          onClick={isTouchDevice ? onTap : onPosterClick}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onMouseEnter={() => setShowPosterOverlay(true)}
          onMouseLeave={() => setShowPosterOverlay(false)}
          sx={{
            position: "relative",
            width: "33%",
            maxWidth: "120px",
            flexBasis: "33%",
            flexShrink: 0,
            borderRadius: 1,
          }}
        >
          <CardActionArea>
            {" "}
            {isLoading ? (
              <Skeleton
                variant="rectangle"
                sx={{ width: "100%", pb: "150%", height: 0, borderRadius: 1 }}
              />
            ) : (
              <CardMedia
                component="img"
                image={`https://image.tmdb.org/t/p/w185/${metaData.poster_path}`}
                sx={{
                  width: "100%",
                  borderRadius: 1,
                  objectFit: "contain",
                  border:
                    user_status !== "none"
                      ? `3px solid ${theme.palette.status[user_status].main}`
                      : "none",
                }}
              />
            )}
          </CardActionArea>
          {showPosterOverlay && (
            <Stack
              direction="column"
              spacing={0}
              sx={{
                position: "absolute",
                top: 3,
                right: 3,
                alignItems: "center",
                backgroundColor: theme.palette.background.paper,
                borderRadius: 1,
                padding: 0.5,
              }}
            >
              {posterActions.length > 0 &&
                posterActions.map((option) => (
                  <Tooltip
                    placement="left"
                    title={
                      user_status === option.value
                        ? option.label
                        : option.action
                    }
                    key={option.value}
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePosterActionClick(option.value);
                      }}
                      sx={{ p: 0.5 }}
                    >
                      {user_status === option.value
                        ? option.iconFilled
                        : option.icon}
                    </IconButton>
                  </Tooltip>
                ))}
            </Stack>
          )}
        </Box>
        <Box
          sx={{
            pl: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            flexBasis: "67%",
            flexGrow: 1,
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", display: "inline" }}
          >
            <Link onClick={onPosterClick} sx={{ cursor: "pointer" }}>
              {movie_title}
            </Link>
            <Typography
              variant="body1"
              component="span"
              sx={{ fontStyle: "italic", ml: 1 }}
            >
              {" "}
              {getYear(movie_release_date)}
            </Typography>{" "}
          </Typography>{" "}
          <Divider />
          <Stack
            direction="row"
            alignItems="center"
            flexWrap="wrap"
            sx={{ mt: -1 }}
          >
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              sx={{ mr: 2, mt: 1, cursor: "pointer" }}
            >
              <RouterLink
                to={`/explore/user/${username}`}
                style={{
                  textDecoration: "none",
                }}
              >
                <Avatar
                  sx={{
                    width: { xs: 30, sm: 40 },
                    height: { xs: 30, sm: 40 },
                    border: "2px solid transparent", // Default transparent border to prevent layout shift
                    "&:hover": {
                      borderColor: "primary.main", // Border color on hover
                    },
                  }}
                  alt={user_given_name}
                  src={user_picture_url}
                />
              </RouterLink>
              <Link component={RouterLink} to={`/explore/user/${username}`}>
                @{username}
              </Link>
            </Stack>
            <Chip
              icon={statusOptions[entry_status].icon}
              size="small"
              label={entry_status}
              sx={{
                bgcolor: theme.palette.status[entry_status].main,
                color: theme.palette.status[entry_status].contrastText,
                "& .MuiChip-icon": {
                  color: theme.palette.status[entry_status].contrastText,
                },
                mr: 2,
                mt: 1,
              }}
            />
            {rating && <Rating value={rating} readOnly={true} sx={{ mt: 1 }} />}
          </Stack>
          {!isBelowSm && mainDiv}
        </Box>
      </Box>
      {isBelowSm && mainDiv}
      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        aria-labelledby="confirm-delete-dialog"
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>
            You have multiple journal entries associated with {movie_title}.
            Removing the status will remove this movie from your library and
            delete all associated journal entries.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenConfirmDelete(false);
              onPosterClick();
            }}
          >
            View journal entries
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenConfirmDelete(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              deleteStatusMutation.mutate({
                tmdb_id: movie_tmdb_id,
                confirm: true,
              });
            }}
            color="error"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}
