import React from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Switch,
  FormControlLabel,
  Typography,
} from "@mui/material";

const SortMenu = ({
  anchorEl,
  onClose,
  onSelect,
  sortByValue,
  toggleValue,
  onToggle,
  sortByOptions,
}) => (
  <Menu
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onClose}
    slotProps={{
      paper: {
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))", // adds shadow around menu
          mt: 1.5,
        },
      },
    }}
  >
    {sortByOptions.map((option) => (
      <MenuItem
        key={option.value}
        onClick={() => onSelect(option.value)}
        selected={option.value === sortByValue}
        disabled={option?.disabled}
        dense
      >
        <ListItemIcon>{option.icon}</ListItemIcon>
        {option.label}
      </MenuItem>
    ))}
    <Divider />
    <MenuItem dense>
      <FormControlLabel
        control={<Switch checked={toggleValue} onChange={onToggle} />}
        label={<Typography variant="body2">Descending</Typography>}
      />
    </MenuItem>
  </Menu>
);

export default SortMenu;
