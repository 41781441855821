// External imports
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Container,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import RefreshIcon from "@mui/icons-material/Refresh";

// Internal imports
import HideOnScroll from "../../components/HideOnScroll.js";
// import AddList from "./AddNew.js";
import {
  getContentOptions,
  getContentOptionsArray,
} from "../../utils/utils.js";
import ListMenu from "../../components/ListMenu.js";
import {
  HeaderButton,
  HeaderToggleButton,
} from "../../components/CustomMUI.js";
import { useIsFetching, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const ExploreHeader = ({
  toggleDrawer,
  drawerWidth,
  content,
  onContentChange,
  onOpenUserSearch,
}) => {
  const theme = useTheme();
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));
  const queryClient = useQueryClient();
  const [contentAnchorEl, setContentAnchorEl] = useState(null);
  const contentOptions = getContentOptions();
  const contentOptionsArray = getContentOptionsArray();
  const isFetchingFeed = useIsFetching({
    queryKey: ["activityFeed", "infinite"],
  });

  const handleContentClick = (event) => {
    setContentAnchorEl(event.currentTarget);
  };
  const handleContentSelect = (option) => (event) => {
    // This function needs to accept an event even though it is not used as that is how the onClick prop of the listMenu component is configured
    onContentChange(option);
    setContentAnchorEl(null);
  };

  const handleRefresh = () => {
    window.scrollTo(0, 0);
    queryClient.setQueryData(["activityFeed", "infinite"], (data) => {
      if (!data || !data.pages) {
        return { pages: [], pageParams: [undefined] };
      }
      return {
        pages: data.pages.slice(0, 1),
        pageParams: data.pageParams.slice(0, 1),
      };
    });
    queryClient.refetchQueries({
      queryKey: ["activityFeed", "infinite"],
      exact: true,
    });
    toast.success("Explore feed updated");
  };

  return (
    <>
      <Toolbar sx={{ mt: 2.5, mb: 2.5 }} />
      {/* The above toolbar acts as a spacer, whatever padding is applied to the below appbar
      needs to be applied as margin to the above spacer, added 0.5 to give space to show librarycard hover scale effects */}
      <HideOnScroll>
        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            backgroundImage: "none",
            width: { md: `calc(100% - ${drawerWidth}px )` },
            ml: { md: `${drawerWidth}px` },
            pt: 2,
            pb: 2,
            overflowX: "auto",
          }}
        >
          <Container maxWidth="sm">
            <Toolbar disableGutters>
              <HeaderToggleButton
                // This is the menu button which only displays when the sidebar is hidden
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer}
                size="small"
                value="menu"
                sx={{
                  mr: 2,
                  display: { md: "none" },
                  "&.MuiButtonBase-root.MuiToggleButton-root": {
                    border: "none",
                    borderRadius: 1,
                  },
                }}
              >
                <MenuIcon />
              </HeaderToggleButton>

              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: "flex-start",
                }}
              >
                <HeaderButton
                  startIcon={contentOptions[content].icon}
                  // endIcon={<ExpandMoreIcon />}
                  onClick={handleContentClick}
                  sx={{
                    mr: 2,
                  }}
                  mobile={isBelowMd}
                >
                  {!isBelowMd && contentOptions[content].label}
                </HeaderButton>
              </Box>

              <HeaderToggleButton
                size="small"
                onClick={handleRefresh}
                disabled={isFetchingFeed > 0}
                value="refresh-activity-feed"
                sx={{
                  mr: 2,
                  "&.MuiButtonBase-root.MuiToggleButton-root": {
                    border: "none",
                    borderRadius: 1,
                    bgcolor: (theme) => theme.palette.secondary.main,
                  },
                }}
              >
                {isFetchingFeed > 0 ? (
                  <CircularProgress size={24} />
                ) : (
                  <RefreshIcon />
                )}
              </HeaderToggleButton>

              <Box>
                {" "}
                {/* Box required to ensure mr is applied to button when toolbar narrows */}
                <HeaderToggleButton
                  size="small"
                  onClick={onOpenUserSearch}
                  value="open-user-search"
                  sx={{
                    mr: { xs: 0, sm: 0 },
                    "&.MuiButtonBase-root.MuiToggleButton-root": {
                      border: "none",
                      borderRadius: 1,
                      bgcolor: (theme) => theme.palette.secondary.main,
                    },
                  }}
                >
                  <PersonSearchIcon />
                </HeaderToggleButton>
              </Box>

              {/* Popup menus... */}
              <ListMenu
                anchorEl={contentAnchorEl}
                onClose={() => setContentAnchorEl(null)}
                onSelect={handleContentSelect}
                options={contentOptionsArray}
              />
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export default ExploreHeader;
