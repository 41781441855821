import React, { useCallback } from "react";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../components/CustomMUI.js";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, InputAdornment } from "@mui/material";

export default function SearchFilter({ searchTerm, onSearchTermChange }) {
  const [isFocused, setIsFocused] = React.useState(false);
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        onSearchTermChange({ target: { value: "" } });
        event.target.blur();
      }
    },
    [onSearchTermChange]
  );

  const handleClear = () => {
    onSearchTermChange({ target: { value: "" } });
  };

  return (
    <Search highlight={!!searchTerm}>
      <SearchIconWrapper highlight={!!searchTerm}>
        <SearchIcon />
      </SearchIconWrapper>

      <StyledInputBase
        id="search-library-by-title"
        placeholder={searchTerm ? "" : "Filter by title"}
        inputProps={{ "aria-label": "search", autoComplete: "off" }}
        value={searchTerm}
        onChange={onSearchTermChange}
        onKeyDown={handleKeyDown}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        endAdornment={
          isFocused || searchTerm ? (
            <InputAdornment position="end" sx={{ pr: 1 }}>
              <IconButton
                aria-label="clear search"
                onClick={handleClear}
                edge="end"
                size="small"
                sx={{ visibility: searchTerm ? "visible" : "hidden" }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ) : undefined
        }
      />
    </Search>
  );
}
