// External imports
import React, { useState, useEffect, useMemo } from "react";
import {
  alpha,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Internal imports
import useApi from "../../hooks/useApi";
import MovieCard from "../../components/MovieCard";
import JournalEntryFixed from "./JournalEntryFixed";
import JournalEntryEditable from "./JournalEntryEditable";
import {
  deleteMovieStatusInCache,
  updateMovieStatusInCache,
} from "../../utils/cacheUtils";
import { toast } from "react-toastify";

function LibraryModal({ open, tmdbId, entryId = null, onClose }) {
  const {
    fetchJournalForMovie,
    fetchStatus,
    editStatus,
    deleteStatus,
    addStatus,
  } = useApi();
  const theme = useTheme();
  const queryClient = useQueryClient();
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const [editingEntryId, setEditingEntryId] = useState(null);
  const [showAllEntries, setShowAllEntries] = useState(false);
  const [logAgain, setLogAgain] = useState(false);

  const statusQuery = useQuery({
    queryKey: ["movie", "status", tmdbId],
    queryFn: () => fetchStatus(tmdbId),
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 1 week
  });

  const status = statusQuery.isSuccess ? statusQuery.data.status : "loading";

  const journalQuery = useQuery({
    queryKey: ["movie", "journal", tmdbId],
    queryFn: () => fetchJournalForMovie(tmdbId),
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 1 week
  });

  const journal = useMemo(() => {
    return Array.isArray(journalQuery.data) ? journalQuery.data : [];
  }, [journalQuery.data]);

  const displayedJournal =
    fullscreen && !!editingEntryId
      ? journal.filter((entry) => entry.journal_id === editingEntryId) // when fullscreen and editing a journal entry, only show the editable entry
      : showAllEntries
      ? journal // otherwise, if show all entries is true show all the journal entries
      : entryId !== null // otherwise if showAllEntries is false and entryId is not null then status = finished in track page
      ? journal.filter((entry) => entry.journal_id === entryId) // show the entry relating to the journal tile that was clicked when status = finished
      : journal.slice(0, 1); // show the most recent journal entry when status != finished

  const isEmptyEntry = (entry) => {
    return (
      entry.rating === null &&
      (entry.reactions?.length === 0 || entry.reactions === null) &&
      !entry.notes
    );
  };

  useEffect(() => {
    // Show journal entries created in last 5 seconds as editable
    // Ensures automatically created entries on status changes prompt further input
    if (journal.length > 0 && isEmptyEntry(journal[0])) {
      const created_at = new Date(journal[0].created_at);
      const currentTime = new Date();
      const timeDiff = (currentTime - created_at) / 1000;
      if (timeDiff <= 5) {
        setEditingEntryId(journal[0].journal_id);
      }
    }
  }, [journal]);

  const handleCloseModal = () => {
    setEditingEntryId(null);
    onClose?.();
  };

  const editStatusMutation = useMutation({
    mutationFn: editStatus,
    onSuccess: (data) => {
      updateMovieStatusInCache(
        queryClient,
        tmdbId,
        status,
        data.statusObject,
        data.entryObject
      );
      toast.success(data.message);
      if (!!entryId && data.statusObject.status !== "finished")
        setShowAllEntries(true);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const deleteStatusMutation = useMutation({
    mutationFn: ({ tmdb_id, confirm }) => deleteStatus(tmdb_id, confirm),
    onSuccess: (data, variables) => {
      if (data.require_confirmation) {
        setOpenConfirmDelete(true);
      } else {
        handleCloseModal(); // close modal first
        deleteMovieStatusInCache(queryClient, variables.tmdb_id); // delete status that modal depends on second
        toast.success(data.message);
      }
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const addStatusMutation = useMutation({
    mutationFn: ({ tmdbId, status, createJournalEntry }) =>
      addStatus(tmdbId, status, createJournalEntry),
    onSuccess: (data, variables) => {
      updateMovieStatusInCache(
        queryClient,
        tmdbId,
        null,
        data.statusObject,
        data.entryObject
      );
      toast.success(data.message);
    },
    onError: (error) => {
      toast.error(error.response?.data?.message);
    },
  });

  const handleStatusChange = (newStatus) => {
    if (newStatus === "logAgain") {
      setLogAgain(true);
      return;
    } else if (newStatus === "none") {
      deleteStatusMutation.mutate({
        tmdb_id: tmdbId,
        confirm: false,
      });
      return;
    } else if (status === "none" && newStatus) {
      addStatusMutation.mutate({
        tmdbId: tmdbId,
        status: newStatus,
        createJournalEntry: true,
      });
      return;
    } else if (status === "finished" && newStatus === "finished") {
      setLogAgain(true);
      editStatusMutation.mutate({
        tmdbId: tmdbId,
        status: newStatus,
        createJournalEntry: false,
      });
      return;
    } else {
      editStatusMutation.mutate({
        tmdbId: tmdbId,
        status: newStatus,
      });
    }
  };

  return (
    <>
      <Dialog
        fullScreen={fullscreen}
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="library-card-dialog"
        fullWidth
        PaperProps={{
          sx: {
            maxWidth: 700,
            boxShadow: "none",
            backgroundImage: "none", // needed to remove default overlay MUI adds in dark mode to desaturate colour
            // bgcolor: alpha(theme.palette.common.black, 0.5),
            backgroundColor: fullscreen
              ? alpha(theme.palette.common.black, 0.5)
              : "transparent",
          },
        }}
      >
        <Box onClick={onClose} sx={{ height: fullscreen ? "100vh" : "auto" }}>
          <Box
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {(!editingEntryId || !fullscreen) && (
              <Box sx={{ mt: 2, mx: fullscreen ? 2 : 0 }}>
                <MovieCard
                  tmdbId={tmdbId}
                  onClose={handleCloseModal}
                  status={status}
                  onStatusChange={handleStatusChange}
                  fullscreen={fullscreen}
                />
              </Box>
            )}

            {logAgain && (
              <Box sx={{ mt: 2, mx: fullscreen ? 2 : 0 }}>
                <JournalEntryEditable
                  mode="add"
                  tmdbId={tmdbId}
                  onAdd={() => setLogAgain(false)}
                  isMobile={fullscreen}
                />
              </Box>
            )}

            {displayedJournal.map((entry) => (
              <Box
                sx={{ mt: 2, mx: fullscreen ? 2 : 0 }}
                key={entry.journal_id}
              >
                {editingEntryId === entry.journal_id ? (
                  <JournalEntryEditable
                    tmdbId={entry.movie_tmdb_id}
                    existingEntry={entry}
                    mode="edit"
                    isMobile={fullscreen}
                    onEdit={() => setEditingEntryId(null)}
                    onDelete={() => {
                      if (entry.journal_id === entryId) {
                        // close modal when entry that modal is based on is deleted
                        onClose();
                      }
                      setEditingEntryId(null);
                    }}
                  />
                ) : (
                  <JournalEntryFixed
                    entry={entry}
                    onEdit={() => setEditingEntryId(entry.journal_id)}
                    isLoading={journalQuery.isFetching}
                    isMobile={fullscreen}
                    selectedEntry={entry.journal_id === entryId}
                  />
                )}
              </Box>
            ))}

            {journal?.length > 1 && !editingEntryId && (
              <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
                <Tooltip
                  title={
                    !showAllEntries
                      ? "Show all entries"
                      : !!entryId
                      ? "Show selected entry only"
                      : "Show most recent entry only"
                  }
                >
                  <IconButton
                    sx={{
                      backgroundColor: theme.palette.tertiary.main,
                      "&:hover": {
                        background: theme.palette.tertiary.main,
                      },
                    }}
                    onClick={() => setShowAllEntries(!showAllEntries)}
                  >
                    {showAllEntries ? (
                      <ExpandLessIcon
                        sx={{ color: theme.palette.common.white }}
                        fontSize="small"
                      />
                    ) : (
                      <ExpandMoreIcon
                        sx={{ color: theme.palette.common.white }}
                        fontSize="small"
                      />
                    )}
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>

      <Dialog
        open={openConfirmDelete}
        onClose={() => setOpenConfirmDelete(false)}
        aria-labelledby="confirm-delete-dialog"
      >
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>
            You have multiple journal entries associated with{" "}
            {statusQuery?.data?.title}. Removing the status will remove this
            movie from your library and delete all associated journal entries.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpenConfirmDelete(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              deleteStatusMutation.mutate({
                tmdb_id: tmdbId,
                confirm: true,
              });
            }}
            color="error"
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default LibraryModal;
