import React from "react";
import { Box, Card, CardActionArea, CardMedia, useTheme } from "@mui/material";
import placeholderPosterLight from "../assets/placeholder-poster.png";
import placeholderPosterDark from "../assets/placeholder-poster-dark.png";
import placeholderPosterDarkest from "../assets/placeholder-poster-dark2.png";
import emptyPosterDark from "../assets/empty-dark.png";
import emptyPosterLight from "../assets/empty-light.png";
import AddIcon from "@mui/icons-material/Add";

export default function AddTile({ onClick, profile = false, readOnly }) {
  const theme = useTheme();
  const cardContent = (
    <>
      <CardMedia
        component="img"
        sx={{
          width: "100%",
          objectFit: "contain",
          borderRadius: 1,
        }}
        image={
          readOnly
            ? theme.palette.mode === "dark"
              ? emptyPosterDark
              : emptyPosterLight
            : theme.palette.mode === "dark"
            ? profile
              ? placeholderPosterDarkest
              : placeholderPosterDark
            : placeholderPosterLight
        }
        // Had to use an actual image with a plain background (colour: #e3e3e3) rather than a skeleton
        // component because skeleton's have no intrinsic dimensions and it was causing problems with creating
        // a responsive collection dialog width
        alt={`placeholder poster`}
      />
      {!readOnly && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <AddIcon fontSize="large" />
        </Box>
      )}
    </>
  );
  return (
    <Card
      onClick={!readOnly ? onClick : undefined}
      sx={{
        width: "100%",
        position: "relative",
        height: "100%",
      }}
    >
      {!readOnly ? <CardActionArea>{cardContent}</CardActionArea> : cardContent}
    </Card>
  );
}
