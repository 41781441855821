import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SettingsMenu from "./SettingsMenu.js";
import useApi from "../hooks/useApi.js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import SettingsIcon from "@mui/icons-material/Settings";

import { useAuth } from "../context/authContext.js";
import Avatar from "@mui/material/Avatar";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddContent from "./AddContentButton.js";
import SearchBar from "./SearchBar.js";
import LibraryModal from "../features/track/LibraryModal.js";
import ProfilePreferences from "../features/profile/ProfilePreferences.js";
import { toast } from "react-toastify";
import {
  Dialog,
  DialogContent,
  SvgIcon,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LogoSmallDark from "../assets/LogoLargeDark.svg";
import LogoSmallLight from "../assets/LogoLargeLight.svg";
import { ReactComponent as TrackIcon } from "../assets/IconTrack.svg";
import { ReactComponent as CurateIcon } from "../assets/IconCurate.svg";
import { ReactComponent as ExploreIcon } from "../assets/IconExplore.svg";
import { updateMovieStatusInCache } from "../utils/cacheUtils.js";

function ResponsiveDrawer({
  handleDrawerToggle, // handled via route-specific app bars
  mobileOpen,
  handleDrawerTransitionEnd,
  handleDrawerClose,
  newUser,
  drawerWidth,
}) {
  // Custom hooks
  const theme = useTheme();
  const { user } = useAuth();
  const { addStatus } = useApi();
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = (path) => location.pathname.startsWith(path);
  const isOnProfilePage =
    location.pathname === `/app/explore/user/${user.username}`;
  const params = new URLSearchParams(location.search);
  const isNewUser = params.get("newUser") === "true";
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  // States
  const [libraryAddition, setLibraryAddition] = useState(null); // tmdb of new item to add to library
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = useState(null);
  const openSettingsMenu = Boolean(settingsMenuAnchorEl);
  const [openSearchbarModal, setOpenSearchbarModal] = useState(false);
  const [openProfilePrefs, setOpenProfilePrefs] = useState(isNewUser);

  // Handlers
  const handleSettingsMenuOpen = (event) => {
    if (settingsMenuAnchorEl !== event.currentTarget) {
      setSettingsMenuAnchorEl(event.currentTarget);
    }
  };

  const handleSettingsMenuClose = () => {
    setSettingsMenuAnchorEl(null);
  };

  const addStatusMutation = useMutation({
    mutationFn: ({ tmdb_id, status, createJournalEntry }) =>
      addStatus(tmdb_id, status, createJournalEntry),
    onSuccess: (data, variables) => {
      updateMovieStatusInCache(
        queryClient,
        variables.tmdb_id,
        null,
        data.statusObject,
        data.entryObject
      );
      setLibraryAddition(variables.tmdb_id);
      // Decided to not open library modal after quick add to avoid duplication of journal entries
      toast.success(data.message);
    },
    onError: (error, variables) => {
      toast.error(error.response?.data?.message);
      setLibraryAddition(variables.tmdb_id);
      // }
    },
  });

  const handleQuickAdd = (tmdb_id, status) => {
    setOpenSearchbarModal(false);
    addStatusMutation.mutate({
      tmdb_id,
      status,
      createJournalEntry: true,
    });
  };

  const handleSearchSelect = (tmdb_id) => {
    setOpenSearchbarModal(false);
    addStatusMutation.mutate({
      tmdb_id,
      status: user.preferences.addButton, // either "finished" or "queued"
      createJournalEntry: true,
    });
    // setLibraryAddition(tmdb_id);
  };

  const handleAddContent = () => {
    setOpenSearchbarModal(true);
  };

  const handleCloseProfilePrefs = () => {
    params.delete("newUser");
    navigate(
      { pathname: location.pathname, search: params.toString() },
      { replace: true }
    );
    setOpenProfilePrefs(false);
  };

  const trianglePointerStyle = {
    position: "absolute",
    right: -10,
    top: "50%",
    transform: "translateY(-50%) rotate(45deg)",
    width: 20,
    height: 20,
    bgcolor: "background.paper",
    zIndex: 0,
  };

  const drawer = (
    <Box display="flex" flexDirection="column" height="100%">
      <Box flexGrow={1}>
        <List>
          <ListItem sx={{ pt: 2 }}>
            <ListItemButton
              component={Link}
              to="/?redirect=false"
              sx={{ px: 0 }}
            >
              <ListItemIcon>
                <img
                  src={
                    theme.palette.mode === "dark"
                      ? LogoSmallDark
                      : LogoSmallLight
                  }
                  alt="Curate Logo"
                  style={{ width: 150 }}
                />
              </ListItemIcon>
            </ListItemButton>
          </ListItem>

          <ListItem key="profile" disablePadding sx={{ pt: 2 }}>
            <ListItemButton
              component={Link}
              to={"/app/explore/user/" + user.username}
            >
              <ListItemIcon>
                <Avatar
                  alt={user.givenName}
                  src={user.picture}
                  loading="lazy"
                  sx={{ display: "flex", bgcolor: "tertiary.main" }}
                />
              </ListItemIcon>
              <ListItemText
                primary={user.givenName}
                primaryTypographyProps={{
                  fontWeight: isOnProfilePage ? "bold" : "undefined",
                  color: (theme) =>
                    isOnProfilePage ? theme.palette.primary.main : "undefined",
                }}
              />
              {isOnProfilePage && !mobileOpen && (
                <Box component="span" sx={trianglePointerStyle} />
              )}
            </ListItemButton>
          </ListItem>
        </List>

        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="/app/track">
              <ListItemIcon>
                <SvgIcon
                  sx={{
                    color: isActive("/app/track") ? "primary.main" : undefined,
                  }}
                >
                  <TrackIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary="Track"
                primaryTypographyProps={{
                  fontWeight: isActive("/app/track") ? "bold" : "undefined",
                  color: (theme) =>
                    isActive("/app/track")
                      ? theme.palette.primary.main
                      : "undefined",
                }}
              />
              {isActive("/app/track") && !mobileOpen && (
                <Box component="span" sx={trianglePointerStyle} />
              )}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton component={Link} to="/app/curate">
              <ListItemIcon>
                <SvgIcon
                  sx={{
                    color: isActive("/app/curate") ? "primary.main" : undefined,
                  }}
                >
                  <CurateIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary="Curate"
                primaryTypographyProps={{
                  fontWeight: isActive("/app/curate") ? "bold" : "undefined",
                  color: (theme) =>
                    isActive("/app/curate")
                      ? theme.palette.primary.main
                      : "undefined",
                }}
              />
              {isActive("/app/curate") && !mobileOpen && (
                <Box component="span" sx={trianglePointerStyle} />
              )}
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            {/* <Tooltip title="Coming soon!"> */}
            {/* <span> */}
            <ListItemButton component={Link} to="/app/explore">
              <ListItemIcon>
                <SvgIcon
                  sx={{
                    color:
                      isActive("/app/explore") && !isOnProfilePage
                        ? "primary.main"
                        : undefined,
                  }}
                >
                  <ExploreIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                primary="Explore"
                primaryTypographyProps={{
                  fontWeight:
                    isActive("/app/explore") && !isOnProfilePage
                      ? "bold"
                      : "undefined",
                  color: (theme) =>
                    isActive("/app/explore") && !isOnProfilePage
                      ? theme.palette.primary.main
                      : "undefined",
                }}
              />
              {isActive("/app/explore") && !isOnProfilePage && !mobileOpen && (
                <Box component="span" sx={trianglePointerStyle} />
              )}
            </ListItemButton>
            {/* </span> */}
            {/* </Tooltip> */}
          </ListItem>
        </List>
      </Box>

      <List>
        {/* <ListItem disablePadding>
          <ListItemButton component={Link} to="/app/test">
            <ListItemIcon>
              <ScienceIcon />
            </ListItemIcon>
            <ListItemText primary="Test" />
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              window.open("https://app.youform.com/forms/mus48b20", "_blank")
            }
          >
            <ListItemIcon>
              <LightbulbIcon />
            </ListItemIcon>
            <ListItemText primary="Request feature" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              window.open("https://app.youform.com/forms/vo0ytyfc", "_blank")
            }
          >
            <ListItemIcon>
              <BugReportIcon />
            </ListItemIcon>
            <ListItemText primary="Report bug" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={handleSettingsMenuOpen}>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="side bar"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRight: 0,
              backgroundImage: "none",
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflowX: "hidden",
              borderRight: 0,
              backgroundImage: "none",
              backgroundColor: (theme) => theme.palette.secondary.main,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <SettingsMenu
        anchorElement={settingsMenuAnchorEl}
        open={openSettingsMenu}
        handleClose={handleSettingsMenuClose}
        handleOpenPrefs={() => setOpenProfilePrefs(true)}
      />
      <AddContent onSelect={handleAddContent} />
      <Dialog
        open={openSearchbarModal}
        onClose={() => setOpenSearchbarModal(false)}
        fullScreen={fullscreen}
        fullWidth
        TransitionProps={{
          onEnter: () => {
            // Reset state in SearchBarContents
          },
        }}
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            paddingTop: fullscreen ? 0 : "25vh",
          },
        }}
        disableRestoreFocus={process.env.NODE_ENV === "development"}
      >
        <DialogContent
          sx={{
            border: "none",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <SearchBar
            onQuickAdd={handleQuickAdd}
            onSelect={handleSearchSelect}
            fullscreen={fullscreen}
            onClose={() => setOpenSearchbarModal(false)}
            title="Add movie to library..."
          />
        </DialogContent>
      </Dialog>

      {libraryAddition && (
        <LibraryModal
          open={!!libraryAddition}
          tmdbId={libraryAddition}
          onClose={() => setLibraryAddition(null)}
        />
      )}

      <ProfilePreferences
        open={openProfilePrefs}
        onClose={handleCloseProfilePrefs}
        newUser={isNewUser}
      />
    </>
  );
}

export default ResponsiveDrawer;
