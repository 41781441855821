// External imports
import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Container,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import GridViewIcon from "@mui/icons-material/GridView";
import MenuIcon from "@mui/icons-material/Menu";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SortIcon from "@mui/icons-material/Sort";

// Internal imports
import HideOnScroll from "../../components/HideOnScroll.js";
import ListMenu from "../../components/ListMenu.js";
import SortMenu from "../../components/SortMenu.js";
import CheckboxFilterMenu from "../../components/CheckboxFilterMenu.js";
import RatingFilterMenu from "../../components/RatingFilterMenu.js";
import SearchAppBar from "../../components/SearchFilter.js";
import {
  HeaderButton,
  HeaderToggleButtonGroup,
  HeaderToggleButton,
} from "../../components/CustomMUI.js";
import {
  getContentOptions,
  getStatusOptions,
  getContentOptionsArray,
  getStatusOptionsArray,
  getSortByOptions,
  getFilterOptions,
  genreOptions,
  decadeOptions,
} from "../../utils/utils.js";

const LibraryHeader = ({
  view,
  onViewChange,
  sortBy,
  onSortByChange,
  sortDescending,
  toggleSortDescending,
  toggleDrawer,
  activeFilters,
  onActiveFiltersChange,
  searchTerm,
  onSearchTermChange,
  status,
  onStatusChange,
  content,
  onContentChange,
  drawerWidth,
}) => {
  const theme = useTheme();
  const contentOptions = getContentOptions();
  const statusOptions = getStatusOptions(theme);
  const sortByOptions = getSortByOptions(status);
  const filterOptions = getFilterOptions(status);
  const statusOptionsArray = getStatusOptionsArray(theme).filter(
    (option) => option.value !== "none"
  );
  const contentOptionsArray = getContentOptionsArray();
  const [filterPrimaryAnchorEl, setFilterPrimaryAnchorEl] = useState(null);
  const [filterSecondaryAnchorEl, setFilterSecondaryAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [contentAnchorEl, setContentAnchorEl] = useState(null);
  const [activeFilterMenu, setActiveFilterMenu] = useState("");
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [selectedDecades, setSelectedDecades] = useState([]);
  const [openSecondaryMenu, setOpenSecondaryMenu] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const currentSortBy = sortByOptions.find((option) => option.value === sortBy);

  const handlePrimaryFilterClick = (event) => {
    setFilterPrimaryAnchorEl(event.currentTarget);
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleStatusClick = (event) => {
    setStatusAnchorEl(event.currentTarget);
  };

  const handleContentClick = (event) => {
    setContentAnchorEl(event.currentTarget);
  };

  const handleCloseMenus = () => {
    setFilterPrimaryAnchorEl(null);
    setFilterSecondaryAnchorEl(null);
    setSortAnchorEl(null);
    setStatusAnchorEl(null);
    setContentAnchorEl(null);

    // Remove filters that have not been fully applied
    onActiveFiltersChange((filters) =>
      filters.filter((filter) => {
        if (Array.isArray(filter.value)) {
          return filter.value.length > 0; // Keeps the filter if the array is not empty
        }
        return filter.value > 0; // // Keeps the filter if the value is non-zero
      })
    );

    setActiveFilterMenu("");
    setSelectedGenres([]);
    setSelectedDecades([]);
  };

  const handleFilterSelect = (type) => (event) => {
    if (!activeFilters.some((filter) => filter.type === type)) {
      const newValue = type === "Genre" || type === "Decade" ? [] : null; // Initialise genre and decade as empty lists and rating as null
      onActiveFiltersChange([...activeFilters, { type, value: newValue }]);
      setOpenSecondaryMenu(type); // set flag to open secondary menu
    }
    setFilterPrimaryAnchorEl(null);
  };

  const handleSortSelect = (option) => {
    onSortByChange(null, option);
    handleCloseMenus();
  };

  const handleStatusSelect = (option) => (event) => {
    // This function needs to accept an event even though it is not used as that is how the onClick prop of the listMenu component is configured
    onStatusChange(option);
    option !== "finished"
      ? onSortByChange(null, "updated_at")
      : onSortByChange(null, "watch_date");
    handleCloseMenus();
  };

  const handleContentSelect = (option) => (event) => {
    // This function needs to accept an event even though it is not used as that is how the onClick prop of the listMenu component is configured
    onContentChange(option);
    handleCloseMenus();
  };

  const handleSecondaryFilterClick = (activeFilterMenu, event) => {
    setActiveFilterMenu(activeFilterMenu);
    setFilterSecondaryAnchorEl(event.currentTarget);

    const currentFilter = activeFilters.find(
      (filter) => filter.type === activeFilterMenu
    );
    if (activeFilterMenu === "Genre") {
      setSelectedGenres(currentFilter ? currentFilter.value : []);
    } else if (activeFilterMenu === "Decade") {
      setSelectedDecades(currentFilter ? currentFilter.value : []);
    }
  };

  const handleApplyFilter = () => {
    // Updates activeFilters state with the current selections (selectedGenres or selectedDecades)
    if (activeFilterMenu === "Genre" && selectedGenres.length > 0) {
      onActiveFiltersChange(
        activeFilters.map((filter) =>
          filter.type === "Genre"
            ? { ...filter, value: selectedGenres }
            : filter
        )
      );
    } else if (activeFilterMenu === "Decade" && selectedDecades.length > 0) {
      onActiveFiltersChange(
        activeFilters.map((filter) =>
          filter.type === "Decade"
            ? { ...filter, value: selectedDecades }
            : filter
        )
      );
    } else {
      // Removes the filter if no selections are made when apply is pressed
      onActiveFiltersChange((filters) =>
        filters.filter((filter) => filter.type !== activeFilterMenu)
      );
    }
    handleCloseMenus();
  };

  const handleClearFilter = () => {
    onActiveFiltersChange(
      activeFilters.filter((filter) => filter.type !== activeFilterMenu)
    );
    handleCloseMenus();
  };

  const handleGenreChange = (genre) => {
    setSelectedGenres((prevSelectedGenres) =>
      prevSelectedGenres.includes(genre)
        ? prevSelectedGenres.filter((g) => g !== genre)
        : [...prevSelectedGenres, genre]
    );
  };

  const handleDecadeChange = (decade) => {
    setSelectedDecades((prevSelectedDecades) =>
      prevSelectedDecades.includes(decade)
        ? prevSelectedDecades.filter((d) => d !== decade)
        : [...prevSelectedDecades, decade]
    );
  };

  const handleRatingChange = (event, newValue) => {
    if (newValue !== null) {
      onActiveFiltersChange(
        activeFilters.map((filter) =>
          filter.type === "Rating" ? { ...filter, value: newValue } : filter
        )
      );
      handleCloseMenus();
    }
  };

  useEffect(() => {
    if (openSecondaryMenu) {
      const button = document.querySelector(`#filter-${openSecondaryMenu}`);
      if (button) {
        button.click();
      }
      setOpenSecondaryMenu(null);
    }
  }, [openSecondaryMenu, activeFilters]);

  return (
    <>
      <Toolbar
        sx={{
          mt: 2.5,
          mb: 2.5,
        }}
      />
      {/* The above toolbar acts as a spacer, whatever padding is applied to the below appbar
      needs to be applied as margin to the above spacer, added 0.5 to give space to show librarycard hover scale effects */}
      <HideOnScroll>
        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            backgroundImage: "none", // needed to remove default overlay MUI adds in dark mode to desaturate colour
            width: { md: `calc(100% - ${drawerWidth}px )` },
            ml: { md: `${drawerWidth}px` },
            pt: 2,
            pb: 2,
            overflowX: "auto",
          }}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <HeaderToggleButton
                // This is the menu button which only displays when the sidebar is hidden
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer}
                size="small"
                value="menu"
                sx={{
                  mr: 2,
                  display: { md: "none" },
                  "&.MuiButtonBase-root.MuiToggleButton-root": {
                    border: "none",
                    borderRadius: 1,
                  },
                }}
              >
                <MenuIcon />
              </HeaderToggleButton>

              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: "flex-start",
                }}
              >
                <HeaderButton
                  startIcon={contentOptions[content].icon}
                  onClick={handleContentClick}
                  sx={{ mr: 2 }}
                  mobile={isMobile}
                >
                  {!isMobile && contentOptions[content].label}
                </HeaderButton>

                <HeaderButton
                  startIcon={statusOptions[status].iconWhite}
                  onClick={handleStatusClick}
                  sx={{
                    mr: 2,
                    color: statusOptions[status].contrastText,
                    backgroundColor: statusOptions[status].color,
                    "&:hover": {
                      backgroundColor: statusOptions[status].color,
                    },
                  }}
                  mobile={isMobile}
                >
                  {!isMobile && statusOptions[status].label}
                </HeaderButton>

                <HeaderButton
                  startIcon={
                    <SortIcon
                      style={{
                        transform: sortDescending ? "none" : "scaleY(-1)", // Flip vertically for ascending sort
                        transition: "transform 0.2s ease", // Optional: Add a smooth transition
                      }}
                    />
                  }
                  onClick={handleSortClick}
                  sx={{ mr: 2 }}
                  mobile={isMobile}
                >
                  {!isMobile && currentSortBy.label}
                </HeaderButton>

                <HeaderButton
                  startIcon={<FilterAltIcon />}
                  onClick={handlePrimaryFilterClick}
                  sx={{ mr: 2 }}
                  mobile={isMobile}
                >
                  {!isMobile && "Filter"}
                </HeaderButton>

                {activeFilters.map((filter, index) => {
                  const currentFilter = filterOptions.find(
                    (option) => option.value === filter.type
                  );
                  return (
                    <HeaderButton
                      key={index}
                      startIcon={currentFilter.icon}
                      onClick={(event) =>
                        handleSecondaryFilterClick(filter.type, event)
                      }
                      id={`filter-${filter.type}`}
                      sx={{ mr: 2 }}
                      variant="outlined"
                      mobile={isMobile}
                    >
                      {!isMobile && filter.type}
                    </HeaderButton>
                  );
                })}
              </Box>

              <SearchAppBar
                searchTerm={searchTerm}
                onSearchTermChange={onSearchTermChange}
              />

              <HeaderToggleButtonGroup
                value={view}
                exclusive
                size="small"
                onChange={onViewChange}
                aria-label="change view"
              >
                <HeaderToggleButton value="grid" aria-label="grid view">
                  <GridViewIcon />
                </HeaderToggleButton>
                <HeaderToggleButton
                  value="list"
                  aria-label="list view"
                  sx={{ mr: { xs: 2, md: 0 } }}
                >
                  <FormatListBulletedIcon />
                </HeaderToggleButton>
              </HeaderToggleButtonGroup>

              {/* Popup menus... */}

              <SortMenu
                anchorEl={sortAnchorEl}
                onClose={handleCloseMenus}
                onSelect={handleSortSelect}
                sortByValue={sortBy}
                toggleValue={sortDescending}
                onToggle={toggleSortDescending}
                sortByOptions={sortByOptions}
              />

              <ListMenu
                anchorEl={filterPrimaryAnchorEl}
                onClose={handleCloseMenus}
                onSelect={handleFilterSelect}
                options={filterOptions}
              />

              <ListMenu
                anchorEl={statusAnchorEl}
                onClose={handleCloseMenus}
                onSelect={handleStatusSelect}
                options={statusOptionsArray}
                selectedValue={status}
              />

              <ListMenu
                anchorEl={contentAnchorEl}
                onClose={handleCloseMenus}
                onSelect={handleContentSelect}
                options={contentOptionsArray}
              />

              {activeFilterMenu === "Genre" && (
                <CheckboxFilterMenu
                  options={genreOptions}
                  selectedOptions={selectedGenres}
                  onChange={handleGenreChange}
                  onApply={handleApplyFilter}
                  onClear={handleClearFilter}
                  anchorEl={filterSecondaryAnchorEl}
                  onClose={handleCloseMenus}
                />
              )}

              {activeFilterMenu === "Decade" && (
                <CheckboxFilterMenu
                  options={decadeOptions}
                  selectedOptions={selectedDecades}
                  onChange={handleDecadeChange}
                  onApply={handleApplyFilter}
                  onClear={handleClearFilter}
                  anchorEl={filterSecondaryAnchorEl}
                  onClose={handleCloseMenus}
                />
              )}

              {activeFilterMenu === "Rating" && (
                <RatingFilterMenu
                  value={
                    activeFilters.find((filter) => filter.type === "Rating")
                      ?.value || null
                  }
                  onChange={handleRatingChange}
                  onClear={handleClearFilter}
                  anchorEl={filterSecondaryAnchorEl}
                  onClose={handleCloseMenus}
                />
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export default LibraryHeader;
