import {
  Box,
  Card,
  Container,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroll-component";

import useApi from "../hooks/useApi.js";

import Header from "../features/explore/Header.js";
import Activity from "../features/explore/Activity.js";
import LibraryModal from "../features/track/LibraryModal.js";
import EmptyActivity from "../features/explore/EmptyActivity.js";
import UserSearch from "../features/explore/UserSearch.js";
import { useOutletContext } from "react-router-dom";
import ErrorPage from "./ErrorPage.js";

export default function Explore() {
  const { toggleDrawer, drawerWidth } = useOutletContext();
  const theme = useTheme();
  const { fetchActivityFeed } = useApi();
  const [view, setView] = useState("grid");
  const [content, setContent] = useState("movies");
  const [sortBy, setSortBy] = useState("updated");
  const [sortDescending, setSortDescending] = useState(true);
  const [libraryModalId, setLibraryModalId] = useState(null);
  const [openUserSearch, setOpenUserSearch] = useState(false);
  const fullscreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleContentChange = (newValue) => {
    setContent(newValue);
  };

  const handleOpenUserSearch = () => {
    setOpenUserSearch(true);
  };

  const handleSortByChange = (event, newSortBy) => {
    if (newSortBy !== null) {
      setSortBy(newSortBy);
    }
  };

  const toggleSortDescending = () => {
    setSortDescending(!sortDescending);
  };

  const feedInfiniteQuery = useInfiniteQuery({
    queryKey: ["activityFeed", "infinite"],
    queryFn: ({ pageParam }) => fetchActivityFeed({ pageParam, self: false }),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  const feed = React.useMemo(
    () => feedInfiniteQuery.data?.pages.flatMap((page) => page.items) || [],
    [feedInfiniteQuery.data]
  );

  if (feedInfiniteQuery.isError) return <ErrorPage />;

  return (
    <Container maxWidth="sm" sx={{ pb: 2 }}>
      <Header
        content={content}
        onContentChange={handleContentChange}
        view={view}
        onViewChange={handleViewChange}
        sortBy={sortBy}
        onSortByChange={handleSortByChange}
        sortDescending={sortDescending}
        toggleSortDescending={toggleSortDescending}
        toggleDrawer={toggleDrawer}
        drawerWidth={drawerWidth}
        onOpenUserSearch={handleOpenUserSearch}
      />

      {feedInfiniteQuery.isPending &&
        [1, 2, 3, 4].map((item) => {
          return (
            <Card
              key={item}
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                p: 2,
                maxWidth: "sm",
                mb: 2,
              }}
            >
              <Skeleton
                sx={{ width: "120px", height: "180px", borderRadius: 2 }}
                variant="rectangle"
              />
              <Stack direction="column" spacing={1} sx={{ flexGrow: 1, pl: 2 }}>
                <Skeleton width="70%" />
                <Skeleton width="60%" />
                <Skeleton width="50%" />
              </Stack>
            </Card>
          );
        })}

      {feed.length === 0 && feedInfiniteQuery.isSuccess && (
        <EmptyActivity onOpenUserSearch={handleOpenUserSearch} />
      )}

      {feedInfiniteQuery.isSuccess && feed.length > 0 && (
        <InfiniteScroll
          dataLength={feed.length}
          next={feedInfiniteQuery.fetchNextPage}
          hasMore={!!feedInfiniteQuery.hasNextPage}
          loader={
            <Box sx={{ pt: 2, pb: 2 }}>
              <LinearProgress />
            </Box>
          }
          style={{ overflow: "visible" }}
          // Overflow visbile required to ensure framer-motion scale animation applied to library cards displays properly
        >
          <Grid container spacing={2} id="infinite-scroll-grid">
            {feed.map((activity) => {
              return (
                <Grid item key={activity.id} xs={12}>
                  <Activity
                    item={activity}
                    onPosterClick={() =>
                      setLibraryModalId(activity.movie_tmdb_id)
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </InfiniteScroll>
      )}

      {!!libraryModalId && (
        <LibraryModal
          open={!!libraryModalId}
          tmdbId={libraryModalId}
          onClose={() => setLibraryModalId(null)}
        />
      )}

      <Dialog
        open={openUserSearch}
        onClose={() => setOpenUserSearch(false)}
        fullScreen={fullscreen}
        fullWidth
        maxWidth={"sm"}
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            paddingTop: fullscreen ? 0 : "25vh",
          },
        }}
        disableRestoreFocus={process.env.NODE_ENV === "development"}
      >
        <DialogContent
          sx={{
            border: "none",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <UserSearch
            onClose={() => setOpenUserSearch(false)}
            fullscreen={fullscreen}
          />
        </DialogContent>
      </Dialog>
    </Container>
  );
}
