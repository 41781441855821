import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import useApi from "../hooks/useApi.js";
import ProfileHeader from "../features/profile/ProfileHeader.js";
import ProfilePicks from "../features/profile/ProfilePicks.js";
import Loading from "../components/Loading.js";
import { useAuth } from "../context/authContext.js";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";

export default function Profile() {
  const { toggleDrawer, drawerWidth } = useOutletContext() || {};
  const { fetchDefaultList, fetchUserProfile } = useApi();
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { username: profileUsername } = useParams();
  const [activeTab, setActiveTab] = useState("movie");
  const isOwnProfile = user?.username === profileUsername;

  useEffect(() => {
    // If user is logged in and viewing a public profile, redirect to the authenticated route
    if (user && location.pathname.startsWith("/explore")) {
      navigate(`/app/explore/user/${profileUsername}`, { replace: true });
    }
  }, [user, profileUsername, location.pathname, navigate]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const profileQuery = useQuery({
    queryKey: ["profile", profileUsername],
    queryFn: () => fetchUserProfile(profileUsername),
    enabled: !!profileUsername,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 1 week
  });

  const monthlyTop1Query = useQuery({
    queryKey: ["defaultList", "top1_monthly", profileUsername],
    queryFn: () => fetchDefaultList("movie", "top1_monthly", profileUsername),
    enabled: !!profileUsername,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 1 week
  });

  const yearlyTop3Query = useQuery({
    queryKey: ["defaultList", "top3_yearly", profileUsername],
    queryFn: () => fetchDefaultList("movie", "top3_yearly", profileUsername),
    enabled: !!profileUsername,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 1 week
  });

  const alltimeTop5Query = useQuery({
    queryKey: ["defaultList", "top5_alltime", profileUsername],
    queryFn: () => fetchDefaultList("movie", "top5_alltime", profileUsername),
    enabled: !!profileUsername,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    cacheTime: 7 * 24 * 60 * 60 * 1000, // 1 week
  });

  if (
    monthlyTop1Query.isLoading ||
    yearlyTop3Query.isLoading ||
    alltimeTop5Query.isLoading ||
    profileQuery.isLoading
  ) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (
    monthlyTop1Query.isError ||
    yearlyTop3Query.isError ||
    alltimeTop5Query.isError ||
    profileQuery.isError
  ) {
    return (
      <div>
        <Typography>
          {" "}
          {monthlyTop1Query.error?.message ||
            yearlyTop3Query.error?.message ||
            alltimeTop5Query.error?.message ||
            profileQuery.error?.message}
        </Typography>
      </div>
    );
  }

  return (
    <>
      <ProfileHeader
        toggleDrawer={toggleDrawer}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
        profile={profileQuery.data}
        isOwnProfile={isOwnProfile}
        drawerWidth={drawerWidth}
      />

      <ProfilePicks
        top1List={monthlyTop1Query.data}
        top3List={yearlyTop3Query.data}
        top5List={alltimeTop5Query.data}
        readOnly={!isOwnProfile}
      />
    </>
  );
}
