// External imports
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Container,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AddIcon from "@mui/icons-material/Add";
import SortIcon from "@mui/icons-material/Sort";

// Internal imports
import HideOnScroll from "../../components/HideOnScroll.js";
import SearchAppBar from "../../components/SearchFilter.js";
import AddList from "./AddNew.js";
import {
  getContentOptions,
  getContentOptionsArray,
} from "../../utils/utils.js";
import { getCollectionSortByOptions } from "../../utils/collectionUtils.js";
import ListMenu from "../../components/ListMenu.js";
import SortMenu from "../../components/SortMenu.js";
import {
  HeaderButton,
  HeaderToggleButton,
} from "../../components/CustomMUI.js";
import { useThemeContext } from "../../context/themeContext.js";

const CollectionsHeader = ({
  toggleDrawer,
  content,
  onContentChange,
  searchTerm,
  sortBy,
  onSortByChange,
  sortDescending,
  toggleSortDescending,
  onSearchTermChange,
  setNewCollectionId,
  drawerWidth,
}) => {
  const { mode } = useThemeContext();
  const theme = useTheme();
  const [addListAnchorEl, setAddListAnchorEl] = useState(null);
  const [contentAnchorEl, setContentAnchorEl] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const contentOptions = getContentOptions();
  const contentOptionsArray = getContentOptionsArray();
  const sortByOptions = getCollectionSortByOptions();
  const currentSortBy = sortByOptions.find((option) => option.value === sortBy);
  const isBelowMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleAddListOpen = (event) => setAddListAnchorEl(event.currentTarget);
  const handleAddListClose = () => setAddListAnchorEl(null);
  const handleContentClick = (event) => {
    setContentAnchorEl(event.currentTarget);
  };
  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };
  const handleContentSelect = (option) => (event) => {
    // This function needs to accept an event even though it is not used as that is how the onClick prop of the listMenu component is configured
    onContentChange(option);
    setContentAnchorEl(null);
  };

  const handleSortSelect = (option) => {
    onSortByChange(null, option);
    setSortAnchorEl(null);
  };

  return (
    <>
      <Toolbar sx={{ mt: 2.5, mb: 2.5 }} />
      {/* The above toolbar acts as a spacer, whatever padding is applied to the below appbar
      needs to be applied as margin to the above spacer, added 0.5 to give space to show librarycard hover scale effects */}
      <HideOnScroll>
        <AppBar
          color="inherit"
          sx={{
            boxShadow: "none",
            backgroundImage: "none",
            width: { md: `calc(100% - ${drawerWidth}px )` },
            ml: { md: `${drawerWidth}px` },
            pt: 2,
            pb: 2,
            overflowX: "auto",
          }}
        >
          <Container maxWidth="lg">
            <Toolbar disableGutters>
              <HeaderToggleButton
                // This is the menu button which only displays when the sidebar is hidden
                aria-label="open drawer"
                edge="start"
                onClick={toggleDrawer}
                size="small"
                value="menu"
                sx={{
                  mr: 2,
                  display: { md: "none" },
                  "&.MuiButtonBase-root.MuiToggleButton-root": {
                    border: "none",
                    borderRadius: 1,
                  },
                }}
              >
                <MenuIcon />
              </HeaderToggleButton>

              <Box
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  flexShrink: 0,
                  justifyContent: "flex-start",
                }}
              >
                <HeaderButton
                  startIcon={contentOptions[content].icon}
                  // endIcon={<ExpandMoreIcon />}
                  onClick={handleContentClick}
                  sx={{
                    mr: 2,
                  }}
                  mobile={isBelowMd}
                >
                  {!isBelowMd && contentOptions[content].label}
                </HeaderButton>

                <HeaderButton
                  startIcon={
                    <SortIcon
                      style={{
                        transform: sortDescending ? "none" : "scaleY(-1)", // Flip vertically for ascending sort
                        transition: "transform 0.2s ease", // Optional: Add a smooth transition
                      }}
                    />
                  }
                  onClick={handleSortClick}
                  sx={{
                    mr: 2,
                  }}
                  mobile={isBelowMd}
                >
                  {!isBelowMd && currentSortBy.label}
                </HeaderButton>
              </Box>

              <SearchAppBar
                searchTerm={searchTerm}
                onSearchTermChange={onSearchTermChange}
              />

              <Box>
                {" "}
                {/* Box required to ensure mr is applied to button when toolbar narrows */}
                <HeaderToggleButton
                  size="small"
                  onClick={handleAddListOpen}
                  value="create-new-button"
                  sx={{
                    mr: { xs: 2, sm: 0 },
                    "&.MuiButtonBase-root.MuiToggleButton-root": {
                      border: "none",
                      borderRadius: 1,
                      bgcolor: (theme) => theme.palette.secondary.main,
                    },
                  }}
                >
                  <AddIcon
                    sx={{ color: mode === "dark" ? "white" : "black" }}
                  />
                </HeaderToggleButton>
              </Box>

              {/* Popup menus... */}
              <ListMenu
                anchorEl={contentAnchorEl}
                onClose={() => setContentAnchorEl(null)}
                onSelect={handleContentSelect}
                options={contentOptionsArray}
              />

              <SortMenu
                anchorEl={sortAnchorEl}
                onClose={() => setSortAnchorEl(null)}
                onSelect={handleSortSelect}
                sortByValue={sortBy}
                toggleValue={sortDescending}
                onToggle={toggleSortDescending}
                sortByOptions={sortByOptions}
              />

              <Popover
                id="Create-new-collection"
                open={Boolean(addListAnchorEl)}
                anchorEl={addListAnchorEl}
                onClose={handleAddListClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                sx={{ mt: 1 }}
              >
                <AddList
                  onClose={handleAddListClose}
                  onAdd={(listId) => setNewCollectionId(listId)}
                />
              </Popover>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export default CollectionsHeader;
