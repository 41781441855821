import React from "react";
import { useThemeContext } from "../../context/themeContext";
import { Box, Card, Stack, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as CurateIcon } from "../../assets/IconCurate.svg";

export default function EmptyCollection({ filtersApplied, onAdd }) {
  const { mode, isTouchDevice } = useThemeContext();
  return (
    <Card
      sx={{
        width: "100%",
        p: 2,
        maxWidth: "sm",
        display: "flex",
        flexDirection: "column",
        boxShadow: "none",
        bgcolor: (theme) =>
          mode === "dark"
            ? theme.palette.background.paper
            : theme.palette.secondary.main,
      }}
    >
      <Stack
        direction={{ xs: "column", sm: "row" }}
        alignItems={{
          xs: "center",
          sm: filtersApplied ? "center" : "flex-start",
        }}
        justifyContent={{ xs: "center", md: "flex-start" }}
        spacing={2}
      >
        <SvgIcon
          sx={{
            width: { xs: 75, sm: 100 },
            height: { xs: 75, sm: 100 },
            color: "primary.main",
          }}
        >
          <CurateIcon />
        </SvgIcon>
        {filtersApplied ? (
          <Typography>
            {" "}
            No collections in your library match your current filters, please
            try again{" "}
          </Typography>
        ) : (
          <>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography gutterBottom>
                Welcome to the Curate page! The place to curate your own unique
                collections of the best (and worst!) of what you've experienced.{" "}
              </Typography>
              <Typography>
                {isTouchDevice ? "Tap" : "Click"} the add button in the top
                right to create your first collection.
              </Typography>
            </Box>
          </>
        )}
      </Stack>
    </Card>
  );
}
