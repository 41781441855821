import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Container } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function ErrorPage() {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm" sx={{ textAlign: "center", mt: 5 }}>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <ErrorOutlineIcon color="error" sx={{ fontSize: 80 }} />
        <Typography variant="h4" fontWeight="bold">
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1" color="textSecondary">
          An unexpected error occurred. Please try again later.
        </Typography>
        <Box display="flex" gap={2} mt={2}>
          <Button variant="outlined" onClick={() => window.location.reload()}>
            Reload Page
          </Button>
          <Button variant="contained" onClick={() => navigate("/")}>
            Go Home
          </Button>
        </Box>
      </Box>
    </Container>
  );
}
