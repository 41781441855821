import { useCallback } from "react";
import axios from "axios";
import { useThemeContext } from "../context/themeContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
const logFetches = process.env.NODE_ENV === "development";

export default function useApi() {
  const { setMode } = useThemeContext();
  const navigate = useNavigate();

  const refreshAccessToken = useCallback(async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    try {
      const response = await axios.post(
        `${API_BASE_URL}/token/refresh`,
        {},
        {
          headers: { Authorization: `Bearer ${refreshToken}` },
          withCredentials: true,
        }
      );
      const { access_token } = response.data;
      localStorage.setItem("access_token", access_token);
      return access_token;
    } catch (refreshError) {
      if (
        refreshError.response &&
        (refreshError.response.status === 401 ||
          refreshError.response.status === 422)
      ) {
        console.log("refresh token expired");
        navigate("/");
      }
    }
  }, [navigate]);

  const apiRequest = useCallback(
    async (url, options) => {
      const makeRequest = async (token) => {
        return axios(url, {
          ...options,
          headers: {
            Authorization: `Bearer ${token}`,
            ...options.headers,
          },
          withCredentials: true,
        });
      };

      try {
        const access_token = localStorage.getItem("access_token");
        let response = await makeRequest(access_token);
        return response;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log(
            "Refreshing access token due to 401 response from /token/auth"
          );
          const newAccessToken = await refreshAccessToken();
          const response = await makeRequest(newAccessToken);
          return response;
        }
        throw error;
      }
    },
    [refreshAccessToken]
  );

  const exchangeCodeForTokens = async (code) => {
    const response = await axios.post(`${API_BASE_URL}/token/exchange`, {
      code,
    });
    return response.data;
  };

  const fetchMovieMetaData = useCallback(
    async (id) => {
      logFetches && console.log("fetchMovieMetaData fired");
      const response = await apiRequest(
        `${API_BASE_URL}/movie/metadata?q=${id}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchBookMetaData = useCallback(
    async (id) => {
      logFetches && console.log("fetchBookMetaData fired");
      const response = await apiRequest(
        `${API_BASE_URL}/book/metadata?q=${id}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchMovieAutocomplete = useCallback(
    async (searchInput) => {
      logFetches && console.log("fetchMovieAutocomplete fired");
      const response = await apiRequest(
        `${API_BASE_URL}/movie/autocomplete?q=${searchInput}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchMovieSearch = useCallback(
    async (searchInput) => {
      logFetches && console.log("fetchMovieSearch fired");
      const response = await apiRequest(
        `${API_BASE_URL}/movie/search?q=${searchInput}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchJournal = useCallback(
    async ({
      pageParam = 1,
      perPage,
      sortBy,
      sortDescending,
      filters,
      searchTerm,
    }) => {
      logFetches && console.log("fetchJournal fired");
      const filterParams = filters
        ? filters.reduce((acc, filter) => {
            const filterKey = `${filter.type.toLowerCase()}_filter`;
            if (Array.isArray(filter.value)) {
              // Join array values for genres and decades into a comma-separated string
              acc[filterKey] = filter.value.join(",");
            } else {
              // Directly assign the value for rating
              acc[filterKey] = filter.value;
            }
            return acc;
          }, {})
        : {}; // If no filters are provided, set to empty object

      const response = await apiRequest(`${API_BASE_URL}/movie/journal`, {
        method: "GET",
        params: {
          page: pageParam,
          per_page: perPage,
          sort_by: sortBy,
          sort_descending: sortDescending, // axios converts boolean parameter to string: "true" or "false"
          search_term: searchTerm,
          ...filterParams,
        },
      });

      return {
        items: response.data.items,
        nextPage: response.data.hasMore ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [apiRequest]
  );

  const editEntry = useCallback(
    async (entryId, editedEntry) => {
      logFetches && console.log("editEntry fired");
      const response = await apiRequest(
        `${API_BASE_URL}/movie/journal/${entryId}`,
        {
          method: "PUT",
          data: editedEntry,
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const addEntry = useCallback(
    async (newEntry) => {
      logFetches && console.log("addEntry fired");
      const response = await apiRequest(`${API_BASE_URL}/movie/journal`, {
        method: "POST",
        data: newEntry,
      });
      return response.data;
    },
    [apiRequest]
  );

  const deleteEntry = useCallback(
    async (entryId) => {
      logFetches && console.log(`deleteEntry fired for entryId ${entryId}`);
      const response = await apiRequest(
        `${API_BASE_URL}/movie/journal/${entryId}`,
        {
          method: "DELETE",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchJournalForMovie = useCallback(
    async (tmdbId) => {
      logFetches &&
        console.log(`fetchJournalForMovie fired for tmdbId = ${tmdbId}`);
      const response = await apiRequest(
        `${API_BASE_URL}/movie/journalformovie/${tmdbId}`,
        {
          method: "GET",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  // Statistics endpoints

  const fetchStats = useCallback(
    async (userId) => {
      logFetches && console.log("fetchStats fired");
      const response = await apiRequest(`${API_BASE_URL}/statistics/movie`, {
        method: "GET",
      });
      return response.data;
    },
    [apiRequest]
  );

  // Status endpoints

  const fetchStatuses = useCallback(
    async ({
      pageParam = 1,
      perPage,
      status,
      sortDescending,
      filters,
      searchTerm,
    }) => {
      logFetches && console.log(`fetchStatuses fired`);
      const filterParams = filters
        ? filters.reduce((acc, filter) => {
            const filterKey = `${filter.type.toLowerCase()}_filter`;
            if (Array.isArray(filter.value)) {
              // Join array values for genres and decades into a comma-separated string
              acc[filterKey] = filter.value.join(",");
            } else {
              // Directly assign the value for rating
              acc[filterKey] = filter.value;
            }
            return acc;
          }, {})
        : {}; // If no filters are provided, set to empty object

      const response = await apiRequest(`${API_BASE_URL}/movie/statuses`, {
        method: "GET",
        params: {
          page: pageParam,
          per_page: perPage,
          status,
          sort_descending: sortDescending, // axios converts boolean parameter to string: "true" or "false"
          search_term: searchTerm,
          ...filterParams,
        },
      });

      return {
        items: response.data.items,
        nextPage: response.data.hasMore ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [apiRequest]
  );

  const addStatus = useCallback(
    async (tmdbId, status, createJournalEntry) => {
      logFetches &&
        console.log(`addStatus fired with tmdbId ${tmdbId}, status ${status}`);
      // N.B. tmdb id not movie id!
      const newStatus = {
        movie_id: tmdbId,
        status: status,
        createJournalEntry: createJournalEntry,
      };
      const response = await apiRequest(`${API_BASE_URL}/movie/statuses`, {
        method: "POST",
        data: newStatus,
      });
      return response.data;
    },
    [apiRequest]
  );

  const editStatus = useCallback(
    async ({ tmdbId, status, createJournalEntry }) => {
      logFetches && console.log("editStatus fired");
      const response = await apiRequest(
        `${API_BASE_URL}/movie/status/${tmdbId}`,
        {
          method: "PUT",
          data: { status, createJournalEntry }, // status is a string, curly braces required to pass it as a JSON object
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const deleteStatus = useCallback(
    async (tmdbId, confirm) => {
      logFetches && console.log(`deleteStatus fired`);
      const response = await apiRequest(
        `${API_BASE_URL}/movie/status/${tmdbId}`,
        {
          method: "DELETE",
          data: { confirm },
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const fetchStatus = useCallback(
    async (tmdbId) => {
      logFetches && console.log(`fetchStatus fired for tmdbId = ${tmdbId}`);
      try {
        const response = await apiRequest(
          `${API_BASE_URL}/movie/status/${tmdbId}`,
          {
            method: "GET",
          }
        );
        return response.data;
      } catch (error) {
        if (
          error.response &&
          error.response.status === 404 &&
          error.response.data.message === "Status not found"
        ) {
          console.log(
            "^ This is not an error; movie just does not have a status yet"
          );
          return { status: "none" }; // return status of "none" if a status object does not yet exist for movie
        } else {
          throw error; // rethrow other errors
        }
      }
    },
    [apiRequest]
  );

  const addMovie = useCallback(
    async (tmdbSearchObject) => {
      logFetches && console.log(`addMovie fired`);
      const response = await apiRequest(`${API_BASE_URL}/movie/add`, {
        method: "POST",
        data: tmdbSearchObject,
      });
      return response.data; // added new line - check if it breaks anything
    },
    [apiRequest]
  );

  const fetchCollections = useCallback(
    async ({
      listId = null,
      isDefault = true,
      sortDescending = true,
      sortBy = "updated",
      searchTerm = "",
      pageParam = 1,
      perPage = 5,
    } = {}) => {
      logFetches && console.log(`fetchCollections fired`);
      let endpoint = `${API_BASE_URL}/lists`; // Default endpoint for lists without specific ID
      if (listId) {
        endpoint = `${API_BASE_URL}/lists/${listId}`;
      } else {
        // Only append the query parameter if no listId is provided
        const params = new URLSearchParams();
        params.append("is_default", isDefault);
        params.append("sort_descending", sortDescending);
        params.append("sort_by", sortBy);
        params.append("search_term", searchTerm);
        params.append("page", pageParam);
        params.append("per_page", perPage);
        endpoint += `?${params.toString()}`;
      }
      const response = await apiRequest(endpoint, {
        method: "GET",
      });
      return {
        items: response.data.items,
        nextPage: response.data.hasMore ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [apiRequest]
  );

  const fetchList = useCallback(
    async (listId) => {
      try {
        logFetches && console.log(`fetchList fired`);
        const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
          method: "GET",
        });
        return response.data;
      } catch (error) {
        console.error("fetchList error: ", error);
        throw error;
      }
    },
    [apiRequest]
  );

  const fetchDefaultList = useCallback(
    async (
      content_type,
      list_type,
      username = null,
      month = null,
      year = null
    ) => {
      logFetches && console.log(`fetchDefaultList fired`);
      const response = await apiRequest(`${API_BASE_URL}/defaultlist`, {
        method: "GET",
        params: {
          content_type,
          list_type,
          ...(username && { username }),
          ...(month && { month }),
          ...(year && { year }),
        },
      });
      return response.data;
    },
    [apiRequest]
  );

  const addMovieToList = useCallback(
    async ({ listId, tmdbId }) => {
      logFetches && console.log(`addMovieToList fired`);
      const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
        method: "POST",
        data: { tmdbId },
      });
      return response.data;
    },
    [apiRequest]
  );

  const removeMovieFromList = useCallback(
    async ({ listId, tmdbId }) => {
      logFetches && console.log(`removeMovieFromList fired`);
      const response = await apiRequest(
        `${API_BASE_URL}/lists/${listId}/movie/${tmdbId}`,
        {
          method: "DELETE",
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const replaceMovieInList = useCallback(
    async ({ listId, oldTmdbId, newTmdbId }) => {
      logFetches && console.log(`replaceMovieInList fired`);
      const response = await apiRequest(
        `${API_BASE_URL}/lists/${listId}/movie/${oldTmdbId}`,
        {
          method: "PUT",
          data: { newTmdbId },
        }
      );
      return response.data;
    },
    [apiRequest]
  );

  const reorderMoviesInList = useCallback(
    async ({ listId, movies }) => {
      logFetches &&
        console.log(`reorderMoviesInList fired for list ID: ${listId}`);
      const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
        method: "PUT",
        data: { movies },
      });
      return response.data;
    },
    [apiRequest]
  );

  const editListDetails = useCallback(
    async ({ listId, title, description, isRanked, size }) => {
      logFetches && console.log(`editListDetails fired for list ID: ${listId}`);
      const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
        method: "PUT",
        data: { title, description, isRanked, size },
      });
      return response.data;
    },
    [apiRequest]
  );

  const createList = useCallback(
    async (newList) => {
      logFetches && console.log(`createList fired`);
      const response = await apiRequest(`${API_BASE_URL}/movie/lists`, {
        method: "POST",
        data: newList,
      });
      return response.data;
    },
    [apiRequest]
  );

  const deleteList = useCallback(
    async (listId) => {
      logFetches && console.log("deleteList fired");
      const response = await apiRequest(`${API_BASE_URL}/lists/${listId}`, {
        method: "DELETE",
      });
      return response.data;
    },
    [apiRequest]
  );

  const fetchActivityFeed = useCallback(
    async ({ pageParam = 1, perPage = 10, self = false }) => {
      logFetches && console.log("fetchActivityFeed fired");
      const response = await apiRequest(`${API_BASE_URL}/feed`, {
        method: "GET",
        params: {
          page: pageParam,
          per_page: perPage,
          self: self,
        },
      });
      return {
        items: response.data.items,
        nextPage: response.data.has_more ? pageParam + 1 : undefined,
        hasMore: response.data.hasMore,
      };
    },
    [apiRequest]
  );

  const checkUsernameAvailability = useCallback(
    async (username) => {
      logFetches && console.log("getUserByUsername fired");
      const response = await apiRequest(`${API_BASE_URL}/check-username`, {
        method: "GET",
        params: {
          username: username,
        },
      });
      return response.data.isAvailable;
    },
    [apiRequest]
  );

  const fetchUserCounts = useCallback(async () => {
    logFetches && console.log("fetchUserCounts fired");
    const response = await apiRequest(`${API_BASE_URL}/user-counts`, {
      method: "GET",
    });
    return response.data;
  }, [apiRequest]);

  const editProfile = useCallback(
    async ({ username, bio, darkModePref, addButtonPref }) => {
      logFetches && console.log("editProfile fired");
      const response = await apiRequest(`${API_BASE_URL}/edit-profile`, {
        method: "PUT",
        data: {
          username,
          bio,
          darkModePref,
          addButtonPref,
        },
      });
      return response.data;
    },
    [apiRequest]
  );

  const authenticateUser = useCallback(async () => {
    logFetches && console.log("authenticateUser fired");
    try {
      const response = await apiRequest(`${API_BASE_URL}/token/auth`, {
        method: "GET",
      });
      const { userDetails } = response.data;
      setMode(userDetails.preferences.darkMode ? "dark" : "light");
      return response.data;
    } catch (error) {
      toast.error(
        "An error occurred while authenticating. Please log in again."
      );
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      return null;
    }
  }, [apiRequest, setMode]);

  const deleteUser = useCallback(
    async (logout) => {
      logFetches && console.log("deleteUser fired");
      try {
        const response = await apiRequest(`${API_BASE_URL}/delete-account`, {
          method: "POST",
        });
        logout();
        toast.success("Account deleted successfully");
        return response.data;
      } catch (error) {
        console.error("Error deleting user account:", error);
        toast.error(
          error.response?.data?.message || "Failed to delete user account"
        );
      }
    },
    [apiRequest]
  );

  const fetchUserSearch = useCallback(
    async (searchInput) => {
      logFetches &&
        console.log("fetchUserSearch fired with input: ", searchInput);
      const response = await apiRequest(
        `${API_BASE_URL}/user/search?q=${encodeURIComponent(searchInput)}`,
        {
          method: "GET",
        }
      );
      console.log("fetchUserSearch response: ", response);
      return response.data;
    },
    [apiRequest]
  );

  const fetchUserProfile = async (username) => {
    logFetches && console.log("fetchUserProfile fired");
    const response = await apiRequest(
      `${API_BASE_URL}/get-profile/${username}`,
      {
        method: "GET",
      }
    );
    return response.data;
  };

  const followUser = async (username) => {
    logFetches && console.log("followUser fired");
    const response = await apiRequest(`${API_BASE_URL}/follow/${username}`, {
      method: "POST",
    });
    return response.data;
  };

  const unfollowUser = async (username) => {
    logFetches && console.log("unfollowUser fired");
    const response = await apiRequest(`${API_BASE_URL}/unfollow/${username}`, {
      method: "DELETE",
    });
    return response.data;
  };

  return {
    exchangeCodeForTokens,
    fetchMovieAutocomplete,
    fetchMovieMetaData,
    fetchBookMetaData,
    fetchJournal,
    fetchStatuses,
    editEntry,
    fetchMovieSearch,
    addEntry,
    fetchJournalForMovie,
    fetchStatus,
    deleteEntry,
    fetchStats,
    fetchCollections,
    fetchList,
    editStatus,
    addMovieToList,
    removeMovieFromList,
    replaceMovieInList,
    reorderMoviesInList,
    deleteList,
    createList,
    editListDetails,
    addStatus,
    addMovie,
    deleteStatus,
    fetchActivityFeed,
    fetchDefaultList,
    checkUsernameAvailability,
    fetchUserCounts,
    editProfile,
    authenticateUser,
    deleteUser,
    fetchUserSearch,
    fetchUserProfile,
    followUser,
    unfollowUser,
  };
}
