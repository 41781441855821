export const updateMovieStatusInCache = (
  queryClient,
  tmdbId,
  oldStatus,
  statusObject, // new status object received from backend
  entryObject // new entry object associated with change of status received from backend
) => {
  queryClient.setQueryData(["movie", "status", tmdbId], () => {
    return statusObject;
  });

  entryObject &&
    queryClient.setQueryData(["movie", "journal", tmdbId], (oldData) => {
      return oldData ? [entryObject, ...oldData] : [entryObject];
    });

  queryClient.setQueryData(["activityFeed", "infinite"], (oldData) => {
    if (!oldData) return oldData; // if the activity doesn't exist, do nothing

    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        items: page.items.map((item) =>
          item.movie_tmdb_id === tmdbId
            ? { ...item, user_status: statusObject.status }
            : item
        ),
      })),
    };
  });

  queryClient.setQueriesData(
    {
      queryKey: ["movie", "statuses", "infinite"],
      exact: false,
      predicate: (query) =>
        Array.isArray(query.queryKey) &&
        query.queryKey.length > 3 &&
        (query.queryKey[3] === oldStatus ||
          (!oldStatus && query.queryKey[3] !== statusObject.status)),
    },
    (oldData) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          items: page.items.filter((item) => item.tmdbId !== tmdbId),
        })),
      };
    }
  );

  queryClient.invalidateQueries({
    queryKey: ["movie", "statuses", "infinite", statusObject.status],
  });
  statusObject.status === "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["movie", "journal", "infinite"],
    });
};

export const deleteMovieStatusInCache = (queryClient, tmdbId) => {
  queryClient.removeQueries({
    queryKey: ["movie", "status", tmdbId],
    exact: "true",
  });
  queryClient.removeQueries({
    queryKey: ["movie", "journal", tmdbId],
    exact: "true",
  });

  queryClient.setQueriesData(
    { queryKey: ["movie", "statuses", "infinite"], exact: false },
    (oldData) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          items: page.items.filter((item) => item.tmdbId !== tmdbId),
        })),
      };
    }
  );

  queryClient.setQueriesData(
    { queryKey: ["movie", "journal", "infinite"], exact: false },
    (oldData) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          items: page.items.filter((item) => item.movie_tmdb_id !== tmdbId),
        })),
      };
    }
  );

  queryClient.setQueryData(["activityFeed", "infinite"], (oldData) => {
    if (!oldData) return oldData; // if the activity doesn't exist, do nothing

    return {
      ...oldData,
      pages: oldData.pages.map((page) => ({
        ...page,
        items: page.items.map((item) =>
          item.movie_tmdb_id === tmdbId
            ? { ...item, user_status: "none" }
            : item
        ),
      })),
    };
  });
};

export const updateMovieEntryInCache = (queryClient, entryObject) => {
  queryClient.setQueryData(
    ["movie", "journal", entryObject.movie_tmdb_id],
    (oldData) => {
      if (!oldData) return oldData;

      return oldData.map((entry) =>
        entry.journal_id === entryObject.journal_id ? entryObject : entry
      );
    }
  );

  // To ensure status objects reorder with most recent at top when editing entry
  entryObject.status !== "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["movie", "statuses", "infinite", entryObject.status],
    });
  entryObject.status === "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["movie", "journal", "infinite"],
    });
};

export const addMovieEntryToCache = (queryClient, entryObject) => {
  queryClient.setQueryData(
    ["movie", "journal", entryObject.movie_tmdb_id],
    (oldData) => {
      return oldData ? [entryObject, ...oldData] : [entryObject];
    }
  );

  entryObject.status !== "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["movie", "statuses", "infinite", entryObject.status],
    });
  entryObject.status === "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["movie", "journal", "infinite"],
    });
};

export const deleteMovieEntryFromCache = (
  queryClient,
  tmdbId,
  deletedEntryId,
  deletedEntryStatus
) => {
  queryClient.setQueryData(["movie", "journal", tmdbId], (oldData) => {
    if (!oldData) return oldData;
    return oldData.filter((entry) => entry.journal_id !== deletedEntryId);
  });

  deletedEntryStatus === "finished" &&
    queryClient.invalidateQueries({
      queryKey: ["movie", "journal", "infinite"],
    });
};

export const updateFollowStatusInCache = (
  queryClient,
  username,
  followStatus
) => {
  const currentUser = queryClient.getQueryData(["currentUser"])?.userDetails;
  if (!currentUser) return;

  const currentUserData = {
    familyName: currentUser.familyName,
    givenName: currentUser.givenName,
    id: currentUser.id,
    picture: currentUser.picture,
    username: currentUser.username,
  };

  queryClient.setQueryData(["profile", username], (oldData) => {
    if (!oldData) return oldData; // Do nothing if target profile not present in the cache

    return {
      ...oldData,
      isFollowing: followStatus,
      followers: followStatus
        ? [currentUserData, ...(oldData.followers || [])]
        : oldData.followers.filter(
            (user) => user.username !== currentUser.username
          ),
    };
  });
};

export const updateListCache = (queryClient, isDefault, type, username) => {
  if (isDefault) {
    queryClient.invalidateQueries({
      queryKey: ["defaultList", type, username],
      exact: true,
    });
  } else {
    queryClient.invalidateQueries({
      queryKey: ["movie", "collections", "infinite"],
    });
  }
};
