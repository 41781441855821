import React from "react";
import { Popover, IconButton, Tooltip, Typography, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmDeletePopover = ({ anchorEl, onCancel, onConfirm }) => (
  <Popover
    anchorEl={anchorEl}
    open={Boolean(anchorEl)}
    onClose={onCancel}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    sx={{ mt: 1.5 }}
  >
    <Box
      sx={{
        p: 1,
        width: "120px",
        bgcolor: (theme) => theme.palette.background.paper,
        textAlign: "left",
      }}
    >
      <Typography variant="body2" gutterBottom>
        Are you sure?
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Tooltip title="Confirm">
          <IconButton color="success" onClick={onConfirm} size="small">
            <CheckIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel">
          <IconButton color="error" onClick={onCancel} size="small">
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  </Popover>
);

export default ConfirmDeletePopover;
