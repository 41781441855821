import React from "react";
import { Container, Typography, Link, Box, Stack, Button } from "@mui/material";
import LogoLargeLight from "../assets/LogoLargeLight.svg";
import LogoLargeDark from "../assets/LogoLargeDark.svg";
import { useTheme } from "@emotion/react";
import { Link as ReactRouterLink } from "react-router-dom";

const PrivacyPolicy = () => {
  const theme = useTheme();
  return (
    <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
      <Box display="flex" justifyContent="flex-end">
        <img
          src={theme.palette.mode === "dark" ? LogoLargeDark : LogoLargeLight}
          alt="Curate Logo"
          sx={{ pb: 10 }}
          width="150px"
        />
      </Box>
      <Typography variant="h4" gutterBottom>
        <strong>Privacy Policy</strong>
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Effective Date:</strong> 4th December 2024
      </Typography>
      <Typography variant="body2" paragraph>
        Welcome to Curate! Your privacy is important to us. This privacy policy
        outlines how we handle your personal information when you use Curate.
      </Typography>

      <Typography variant="h6" gutterBottom>
        <strong>1. Information We Collect</strong>
      </Typography>
      <Typography variant="body2" paragraph>
        When you use Curate, we collect:
      </Typography>
      <Typography variant="body2" component="ul" sx={{ pl: 2, mb: 2 }}>
        <li>
          Your name, email address, and profile picture via Google Sign-In when
          you authenticate.
        </li>
        <li>A username that you create for your Curate profile.</li>
        <li>
          Content and preferences you log within Curate (e.g., movies, books, TV
          shows, etc.).
        </li>
      </Typography>
      <Typography variant="body2" paragraph>
        We do not collect or store sensitive information such as payment details
        or physical addresses.
      </Typography>

      <Typography variant="h6" gutterBottom>
        <strong>2. How We Use Your Information</strong>
      </Typography>
      <Typography variant="body2" paragraph>
        We use your information to:
      </Typography>
      <Typography variant="body2" component="ul" sx={{ pl: 2, mb: 2 }}>
        <li>Provide and maintain the Curate service.</li>
        <li>
          Personalize your experience (e.g., showing your profile picture and
          username).
        </li>
        <li>
          Respond to feedback or inquiries you submit through the "Leave
          Feedback" link.
        </li>
      </Typography>
      <Typography variant="body2" paragraph>
        We do not sell or share your personal information with third parties.
      </Typography>

      <Typography variant="h6" gutterBottom>
        <strong>3. Data Retention</strong>
      </Typography>
      <Typography variant="body2" paragraph>
        We retain your information for as long as you use Curate. If you delete
        your account, all associated data will be permanently removed.
      </Typography>

      <Typography variant="h6" gutterBottom>
        <strong>4. Your Rights</strong>
      </Typography>
      <Typography variant="body2" paragraph>
        You have the right to:
      </Typography>
      <Typography variant="body2" component="ul" sx={{ pl: 2, mb: 2 }}>
        <li>Access the personal information we store about you.</li>
        <li>Request deletion of your account and associated data.</li>
      </Typography>
      <Typography variant="body2" paragraph>
        To exercise these rights,{" "}
        <Link href="https://app.youform.com/forms/cg9eliij" underline="hover">
          contact us
        </Link>{" "}
        and let us know your request.
      </Typography>

      <Typography variant="h6" gutterBottom>
        <strong>5. Data Security</strong>
      </Typography>
      <Typography variant="body1" paragraph>
        We take reasonable steps to secure your information but cannot guarantee
        absolute security due to the nature of the internet.
      </Typography>

      <Typography variant="h6" gutterBottom>
        <strong>6. Changes to this Policy</strong>
      </Typography>
      <Typography variant="body2" paragraph>
        We may update this policy from time to time. The latest version will
        always be available on our website.
      </Typography>

      <Typography variant="h6" gutterBottom>
        <strong>7. Questions or concerns?</strong>
      </Typography>
      <Typography variant="body2" paragraph>
        <Link href="https://app.youform.com/forms/cg9eliij" underline="hover">
          Contact us
        </Link>{" "}
        and we'll do our best to help.
      </Typography>
      <Box
        id="footer-section"
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
          pr: { xs: 0, md: 2 },
        }}
      >
        <Stack direction="row" spacing={1} alignItems="center">
          <Button
            component={ReactRouterLink}
            to="/?redirect=false"
            sx={{
              color: (theme) => theme.palette.text.primary,
              fontWeight: "normal",
              typography: "body2",
            }}
          >
            {" "}
            🏠 Back home
          </Button>
        </Stack>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
